
































































import Vue from 'vue';
import {Component, Emit, Prop} from 'vue-property-decorator';
import {MenuInterface} from '@/shared/interfaces/store/ui/menu.interface';
import {LanguageInterface} from '@/shared/interfaces/store/ui/language.interface';
import {UiConfigStore} from '@/store/modules/ui-config';
import SignUpButton from '@/components/common/SignUpButton.vue';
@Component({
  components: {SignUpButton}
})
export default class MobileMenu extends Vue {

  @Prop()
  menu: MenuInterface[];

  mobileOpenedId = -1;

  get languages(): LanguageInterface[] {
    return UiConfigStore.languages;
  }

  get signUpLink(): string {
    return UiConfigStore.signUpLink;
  }

  get currentLanguage(): LanguageInterface | undefined {
    return this.languages.find(lang => lang.value === this.$route.params.lang);
  }

  @Emit()
  navigate(link: string): string {
    return link;
  }

  @Emit()
  changeLanguage(lang: string): string {
    return lang;
  }

  toggle(id: number, event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.mobileOpenedId === id) {
      this.mobileOpenedId = -1;
    } else {
      this.mobileOpenedId = id;
    }
  }
}
